<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">{{ eventLocal.title }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Form -->
        <b-form class="p-2">
          <!-- Title -->
          <b-form-group label-for="event-title">
            <template v-slot:label>
              {{ $t("message.calendar.title") }}
            </template>
            <b-form-input
              id="event-title"
              v-model="eventLocal.title"
              readonly
            />
          </b-form-group>

          <!-- Type -->
          <b-form-group label-for="event-type">
            <template v-slot:label>
              {{ $t("message.calendar.type") }}
            </template>
            <b-form-input id="event-type" v-model="eventLocal.type" readonly />
          </b-form-group>

          <!-- User -->
          <b-form-group label-for="event-user">
            <template v-slot:label>
              {{ $t("message.calendar.users") }}
            </template>
            <b-form-textarea id="event-user" v-model="members" readonly />
          </b-form-group>

          <!-- Date -->
          <b-form-group
            label-for="start-date"
            v-if="eventLocal.type === 'Lancamento'"
          >
            <template v-slot:label>
              {{ $t("message.calendar.date") }}
            </template>
            <b-form-input
              v-model="eventLocal.end"
              class="form-control"
              type="date"
              readonly
            />
          </b-form-group>

          <!-- Final Date -->
          <b-form-group label-for="start-date" v-else>
            <template v-slot:label>
              {{ $t("message.calendar.finalDate") }}
            </template>
            <b-form-input
              v-model="eventLocal.end"
              class="form-control"
              type="date"
              readonly
            />
          </b-form-group>

          <!-- Textarea 
          <b-form-group label="Descrição" label-for="event-description">
            <b-form-textarea
              id="event-description"
              v-model="eventLocal.description"
              readonly
            />
          </b-form-group>-->
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs } from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEventHandlerSidebarActive",
    event: "update:is-event-handler-sidebar-active",
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,

      flatConfig: {
        dateFormat: "d/m/Y",
      },

      members: "",
    };
  },

  watch: {
    eventLocal: function () {
      this.refreshMembers();
    },
  },

  methods: {
    refreshMembers() {
      this.members = "";
      this.members = this.eventLocal.members.join(", ");
    },
  },

  setup(props, { emit }) {
    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      // UI
      onSubmit,
    } = useCalendarEventHandler(toRefs(props), emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetEventLocal, props.clearEventData);

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
