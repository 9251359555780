import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";
import AccessControl from "@core/utils/access-control";

function restrictions(value) {
  return AccessControl(value);
};

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: "success",
        label: "Tarefa",
      },
      {
        color: "warning",
        label: "Lancamento",
      },
    ],
    selectedCalendars: ["Tarefa", "Lancamento"],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val;
    },
  }, 
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        //CurrentUser
        var userData = store.state.user.userData;

        if (calendars.length === 0) {
          calendars.push("_NONE");
        }

        if (restrictions("calendar-list-all")) {
          axios
            .get(`${URL_API}calendar/${calendars}`, {
              headers: getHeader(userData),
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        } else {
          axios
            .get(`${URL_API}calendar/${userData.id}/${calendars}/user`, {
              headers: getHeader(userData),
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        }
      });
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: `${URL_API}calendar`,
          headers: getHeader(userData),
          data: event,
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "put",
          url: `${URL_API}calendar/${event.id}`,
          headers: getHeader(userData),
          data: event,
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "delete",
          url: `${URL_API}calendar/${id}`,
          headers: getHeader(userData),
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
