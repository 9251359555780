export default function locale(i18n) {
  if (i18n === "en") {
    return {
      allDayText: "All day",
      buttonText: {
        day: "Day",
        list: "List",
        month: "Month",
        next: "Next",
        prev: "Prev",
        today: "Today",
        week: "Week",
      },
      code: "en",
      moreLinkText: "more",
      noEventsText: "No more events to show",
      week: { dow: 1, doy: 4 },
      weekText: "Sem",
    };
  } else if (i18n === "pt") {
    return {
      allDayText: "Todo o dia",
      buttonText: {
        day: "Dia",
        list: "Agenda",
        month: "Mês",
        next: "Seguinte",
        prev: "Anterior",
        today: "Hoje",
        week: "Semana",
      },
      code: "pt",
      moreLinkText: "mais",
      noEventsText: "Não há eventos para mostrar",
      week: { dow: 1, doy: 4 },
      weekText: "Sem",
    };
  } else if (i18n === "es") {
    return {
      allDayText: "Todo el día",
      buttonText: {
        day: "Día",
        list: "Lista",
        month: "Mes",
        next: "Siguiente",
        prev: "Anterior",
        today: "Hoy",
        week: "Semana",
      },
      code: "es",
      moreLinkText: "más",
      noEventsText: "No hay más eventos para mostrar",
      week: { dow: 1, doy: 4 },
      weekText: "Sem",
    };
  }
}
